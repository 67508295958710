import '@/scss/index.scss';


import { createApp } from 'vue';
import testComponent from './components/TestComponent.vue';
import OverlayButtonWrapper from './components/OverlayButtonWrapper.vue';
import MemberFilter from './components/MemberFilter.vue';
import ScrollUp from './components/ScrollUp.vue';
import Masonry from './components/Masonry.vue';
import Lightbox from './components/Lightbox.vue';
import Animations from './components/Animations.vue';
/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if ((import.meta as any).hot) {
    (import.meta as any).hot.accept(() => {
        console.log("HMR");
    });
}


const app = createApp({
    components: {
        testComponent,
        OverlayButtonWrapper,
        MemberFilter,
        ScrollUp,
        Masonry,
        Lightbox,
        Animations
    },
}
);



app.mount('#app');